import { FC } from 'react';
import { Divider } from '@material-ui/core';
import { API } from 'aws-amplify';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  getIngredientTransportDistance,
  retrieveDefaultDistances,
} from '../../../../../graphql/queries';
import InboundTransportInputGroup from './InboundTransportInputGroup';
import {
  FormType,
  InboundTransportKey,
  IngredientField,
  IngredientNamesAndOriginsV2,
  IngredientUses,
  UserProfileAlias,
} from '../types';
import {
  ADDON_INGREDIENT_SEPARATOR,
  CUSTOM_INGREDIENT_ID_SEPARATOR,
  isFeedV2CFDatabaseFoundation,
  masterDataV2,
} from '../utils';
import {
  CompoundFeedInputComponentsData,
  IngredientTransportDistance,
  ModelTransportDistanceConnection,
  Transport,
  TypeUnitValue,
} from '../../../../../graphql/types';
import { useIntl } from '../../../../../_metronic/i18n/customUseIntl';
import { convertDistanceUnitsV2 } from '../mapper_v2/UnitsConverterV2';
import { CFDatabaseFoundation } from '../../../models/CompoundFeed';
import { getDatabase } from '../../../utils/compound-feed-utils';

interface IngredientInputForDistanceCalc {
  name: string;
  origin: string;
  addonType?: string;
}

interface InboundTransportProps {
  formType: FormType;
  compoundFeedInputData: CompoundFeedInputComponentsData;
  // eslint-disable-next-line react/require-default-props
  ingredientOriginCombinations?: IngredientUses[];
  // eslint-disable-next-line react/require-default-props
  ingredientOriginCombinationsCategories?: IngredientUses[];
  setMsg: (message: string) => void;
  userProfile: UserProfileAlias;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  convertDistanceUnits: (
    value: any,
    type: any,
    userUOM: any,
    originUnit: any,
    direction?: number
  ) => { value: any; unit: any };
  setLoadingData: (loading: boolean) => void;
  customerID: string;
  databaseFoundation: CFDatabaseFoundation | undefined;
  ingredientOriginsNamesV2: IngredientNamesAndOriginsV2;
}

const InboundTransport: FC<InboundTransportProps> = ({
  formType,
  compoundFeedInputData,
  ingredientOriginCombinations = [],
  ingredientOriginCombinationsCategories = [],
  setMsg,
  userProfile,
  convertDistanceUnits,
  setLoadingData,
  customerID,
  databaseFoundation,
  ingredientOriginsNamesV2,
}) => {
  const intl = useIntl();

  const inboundTransportTypes: string[] = (
    !isFeedV2CFDatabaseFoundation(databaseFoundation)
      ? compoundFeedInputData?.inbound_transport_types || []
      : masterDataV2.inbound_transport_types
  ).map((item) => item as string);

  const formContext = useFormContext();
  const { control } = formContext;
  const ingredientUses = useWatch({ control, name: 'ingredient_uses' }) as IngredientField[];

  // TODO: leave userUOM until in TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();

  const getDefaultDistances = (
    ingredientID: string,
    ingredientOrigin: string,
    feedMillLocation: string,
    index: number
  ) => {
    setLoadingData(true);

    if (!isFeedV2CFDatabaseFoundation(databaseFoundation)) {
      // if custom ingredient, get ID
      if (
        ingredientID &&
        ingredientID.indexOf(CUSTOM_INGREDIENT_ID_SEPARATOR) > -1
      )
        // eslint-disable-next-line no-param-reassign
        ingredientID = ingredientID.substring(
          0,
          ingredientID.indexOf(CUSTOM_INGREDIENT_ID_SEPARATOR)
        );

      (
        API.graphql({
          query: retrieveDefaultDistances,
          variables: {
            ingredientID,
            ingredientOrigin,
            feedMillLocation,
            customerId: customerID,
          },
        }) as Promise<{
          data?: {
            retrieveDefaultDistances?: ModelTransportDistanceConnection;
          };
        }>
      ).then(
        (response: {
          data?: {
            retrieveDefaultDistances?: ModelTransportDistanceConnection;
          };
        }) => {
          setLoadingData(false);

          // if distance calculated
          if (response.data?.retrieveDefaultDistances?.status === 200) {
            const responseData =
              response.data?.retrieveDefaultDistances?.items || [];
            responseData.forEach((item: TypeUnitValue) => {
              // TODO: leave until convertDistanceUnits in TS
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              const { value } = convertDistanceUnits(
                item.value,
                item.type,
                userUOM,
                item.unit
              ) as { value?: number };
              formContext.setValue(
                `ingredient_uses.${index}.inbound_transport_input.${item.type.replace(
                  / /g,
                  '_'
                )}`,
                value
              );
            });
          }
          // distance is not calculated, unavailable
          else if (response.data?.retrieveDefaultDistances?.status === 400)
            setMsg(
              intl.formatMessage({
                id: 'COMPOUND_FEED.INBOUND_TRANSPORT.CALCULATE_DISTANCE.UNAVAILABLE',
              })
            );
          // some other error
          else
            setMsg(
              intl.formatMessage(
                {
                  id: 'COMPOUND_FEED.INBOUND_TRANSPORT.CALCULATE_DISTANCE.ERROR',
                },
                {
                  reason:
                    response.data?.retrieveDefaultDistances?.message || '',
                }
              )
            );
        },
        (reason: { errors: { message: string }[] }) => {
          setLoadingData(false);
          const error = reason?.errors?.[0];
          setMsg(
            intl.formatMessage(
              {
                id: 'COMPOUND_FEED.INBOUND_TRANSPORT.CALCULATE_DISTANCE.ERROR',
              },
              { reason: error?.message || '' }
            )
          );
        }
      );
    } else {
      let ingredientOriginalName: string = ingredientID;
      const customSeparatorIndex: number = ingredientID.indexOf(
        CUSTOM_INGREDIENT_ID_SEPARATOR
      );
      const addonSeparatorIndex: number = ingredientID.indexOf(
        ADDON_INGREDIENT_SEPARATOR
      );
      if (customSeparatorIndex > -1)
        ingredientOriginalName = ingredientID.substring(
          0,
          customSeparatorIndex
        );
      else if (addonSeparatorIndex > -1)
        ingredientOriginalName = ingredientID.substring(0, addonSeparatorIndex);

      const ingredient: IngredientInputForDistanceCalc = {
        name: ingredientOriginalName,
        origin: ingredientOrigin,
      };

      if (addonSeparatorIndex > -1)
        ingredient.addonType = ingredientID.substring(
          addonSeparatorIndex + ADDON_INGREDIENT_SEPARATOR.length
        );

      (
        API.graphql({
          query: getIngredientTransportDistance,
          variables: {
            databaseFoundation: getDatabase(databaseFoundation),
            ingredient,
            feedmillLocationIsoCode: feedMillLocation,
            customerId: customerID,
          },
        }) as Promise<{
          data?: {
            getIngredientTransportDistance?: IngredientTransportDistance[];
          };
        }>
      )
        .then((res) => {
          setLoadingData(false);
          // console.log('distance returned: ', res);

          if (res === null || !res.data?.getIngredientTransportDistance) {
            setMsg(
              intl.formatMessage({
                id: 'COMPOUND_FEED.INBOUND_TRANSPORT.CALCULATE_DISTANCE.UNAVAILABLE',
              })
            );
          } else {
            const transports: Transport[] = [];
            res.data.getIngredientTransportDistance.forEach((t) => {
              if (!t.mode) {
                return;
              }
              transports.push({
                mode: t.mode,
                distance: t.distance || 0,
              });
            });
            const transportConverted = convertDistanceUnitsV2(
              transports,
              userUOM
            ); // to convert to uom

            Object.keys(transportConverted).forEach((key) => {
              formContext.setValue(
                `ingredient_uses.${index}.inbound_transport_input.${key}`,
                transportConverted[key as InboundTransportKey]
              );
            });
          }
        })
        .catch((err) => {
          setLoadingData(false);
          // eslint-disable-next-line no-console
          console.log('err returned from distance', err);
        });
    }
  };

  const getName = (customIngredient: boolean, index: number): string | null => {
    // if not custom, return ingredient name
    const id = formContext.getValues(`ingredient_uses.${index}.name`) as string;

    if (!customIngredient) {
      if (id && id.indexOf(ADDON_INGREDIENT_SEPARATOR) !== -1) {
        return `${id.replace(
          ADDON_INGREDIENT_SEPARATOR,
          ` - ${intl.formatMessage({ id: 'GENERAL.ADDON' })} (`
        )})`;
      }

      return (
        ingredientOriginCombinations?.find((item) => item?.id === id)?.name ||
        null
      );
    }


    // custom ingredient
    if (id) {
      const separatorIndex = id.indexOf(CUSTOM_INGREDIENT_ID_SEPARATOR);
      // get category name
      const ingredientId = id.substring(0, separatorIndex);
      let ingredientCategoryName = null;
      if (ingredientId)
        ingredientCategoryName =
          ingredientOriginCombinationsCategories?.find(
            (item) => item?.id === ingredientId
          )?.name || null;

      // get custom name
      const customName =
        separatorIndex > -1
          ? id.substring(separatorIndex + CUSTOM_INGREDIENT_ID_SEPARATOR.length)
          : id;

      let fullName = customName || '';
      if (ingredientCategoryName)
        fullName = `${fullName} (${ingredientCategoryName})`;

      return fullName;
    }
    return null;
  };

  return (
    <>
      {ingredientUses.map(
        (field: IngredientField, index: number) => {
          // console.log("index: ", index, field.proxyOriginCode, field.origin);
          // NOTE: in order to prperly handle adding and especially deleting the render needs unique key,
          const keyString = field.localId;
          return (
            // If ingredient_id is used for the key, for the first ingredient two inbound transport rows are shown
            // if ingredient_id is concatenated with name, then one row is shown, but data is not filled,
            // only after reloading page it is filled properly
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            <div>
              {index > 0 && (
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                <Divider key={`inbt_divider_${keyString}`} />
              )}
              <InboundTransportInputGroup
                // eslint-disable-next-line react/no-array-index-key, @typescript-eslint/restrict-template-expressions
                key={field.key}
                value={{
                  name: getName(field.customIngredient || false, index),
                  ingredientId: field.name,
                  origin: field.origin,
                }}
                index={index}
                field={field}
                formType={formType}
                inbound_transport_types={inboundTransportTypes}
                getDefaultDistanceHandler={getDefaultDistances}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                userProfile={userProfile}
                databaseFoundation={databaseFoundation}
                ingredientOriginsNamesV2={ingredientOriginsNamesV2}
              />
            </div>
          );
        }
      )}
    </>
  );
};

export default InboundTransport;
